import React from "react"
import Seo from "../components/SEO"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import paths from "../constants/paths"

const Container = styled.div`
  position: relative;

  p {
    word-wrap: break-word;
  }

  > section {
    padding: 0;
    background: rgba(245, 244, 233, 1);
    z-index: 10;
    padding: 1rem;
  }

  h2 {
    margin-top: 0;
    padding-top: 1rem;
  }
  
  @media (min-width: 768px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    
    > section {
      margin: 0 auto;
      border-radius: 25px;
      width: 80vw;
      max-width: 1000px;  
    }
  }
`;

const BackgroundImage = styled.div`
  display: none;
  visibility: hidden;

  @media (min-width: 768px) {
    display: block;
    visibility: visible;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;

    .gatsby-image-wrapper, img {
      height: 100vh;
      width: 100vw;
    }
  }
`

const PrivacyPolicy = ({ location }) => {
  return (
    <Container>
      <Seo title={paths.privacyPolicy.title} location={location} />
      <BackgroundImage>
        <StaticImage alt="Hurricane Ridge Adventure Wedding Olympic National Park Elopement Photographer 4" src="../images/theme/mountains/Mt-Rainier-Paradise-2.jpg" width={1440} />
      </BackgroundImage>
      <section>
        <h1>Privacy Policy</h1>

        <p>
          Protecting your private information is our priority. This Statement of Privacy applies to bettyelainephotography.com, and Photography by Betty Elaine LLC and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Photography by Betty Elaine LLC include bettyelainephotography.com and Photography by Betty Elaine. The Photography by Betty Elaine website is a marketing site. By using the Photography by Betty Elaine website, you consent to the data practices described in this statement.
        </p>

        <h2>Collection of your Personal Information </h2>
        <p>In order to better provide you with products and services offered, Photography by Betty Elaine may collect personally identifiable information, such as your: </p>
        <ul>
          <li>First and Last Name </li>
          <li>E-mail Address</li>
          <li>Phone Number </li>
          <li>Preferred Pronouns</li>
          <li>Contact Form Questionnaire</li>
        </ul>

        <p>Photography by Betty Elaine may also collect anonymous demographic information, which is not unique to you, such as your: </p>

        <ul>
          <li>Age </li>
          <li>Gender </li>
        </ul>

        <p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future. </p>
        <h2>Use of your Personal Information </h2>
        <p>Photography by Betty Elaine collects and uses your personal information to operate and deliver the services you have requested. </p>
        <p>Photography by Betty Elaine may also use your personally identifiable information to inform you of other products or services available from Photography by Betty Elaine and its affiliates. </p>
        <h2>Sharing Information with Third Parties </h2>
        <p>Photography by Betty Elaine does not sell, rent or lease its customer lists to third parties. </p>
        <p>Photography by Betty Elaine may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Photography by Betty Elaine, and they are required to maintain the confidentiality of your information. </p>
        <p>Photography by Betty Elaine may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Photography by Betty Elaine or the site; (b) protect and defend the rights or property of Photography by Betty Elaine; and/or (c) act under exigent circumstances to protect the personal safety of users of Photography by Betty Elaine, or the public. </p>
        <h2>Tracking User Behavior </h2>
        <p>Photography by Betty Elaine may keep track of the websites and pages our users visit within Photography by Betty Elaine, in order to determine what Photography by Betty Elaine services are the most popular. This data is used to deliver customized content and advertising within Photography by Betty Elaine to customers whose behavior indicates that they are interested in a particular subject area. </p>
        <hr />
        <h2>Automatically Collected Information </h2>
        <p>Information about your computer hardware and software may be automatically collected by Photography by Betty Elaine. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Photography by Betty Elaine website. </p>
        <h2>Security of your Personal Information </h2>
        <p>Photography by Betty Elaine secures your personal information from unauthorized access, use, or disclosure. Photography by Betty Elaine uses the following methods for this purpose: </p>
        <ul><li>SSL Protocol </li></ul>
        <p>When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. </p>
        <p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed. </p>
        <h2>Right to Deletion </h2>
        <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will: </p>
        <ul>
          <li>Delete your personal information from our records; and </li>
          <li>Direct any service providers to delete your personal information from their records</li>
        </ul>
        <p>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to: </p>
        <ul>
          <li>Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us; </li>
          <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity; </li>
          <li>Debug to identify and repair errors that impair existing intended functionality; </li>
          <li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law; </li>
          <li>Comply with the California Electronic Communications Privacy Act; </li>
          <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent; </li>
          <li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us; </li>
          <li>Comply with an existing legal obligation; or </li>
          <li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information. </li>
        </ul>
        <h2>Children Under Thirteen </h2>
        <p>Photography by Betty Elaine does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website. </p>
        <h2>E-mail Communications </h2>
        <p>From time to time, Photography by Betty Elaine may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. </p>
        <p>If you would like to stop receiving marketing or promotional communications via email from Photography by Betty Elaine, you may opt out of such communications by Send an email to contact@bettyelainephotography.com. </p>
        <h2>External Data Storage Sites </h2>
        <p>We may store your data on servers provided by third party hosting vendors with whom we have contracted. </p>
        <h2>Changes to this Statement </h2>
        <p>Photography by Betty Elaine reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy. </p>
        <h2>Contact Information </h2>
        <p>Photography by Betty Elaine welcomes your questions or comments regarding this Statement of Privacy. If you believe that Photography by Betty Elaine has not adhered to this Statement, please contact Photography by Betty Elaine at: </p>
        <p>
          Photography by Betty Elaine LLC<br/>
          12525 20th Ave NE<br/>
          Seattle, Washington 98125<br/>
          <br/>
          Email Address:<br/>
          contact@bettyelainephotography.com<br/>
        </p>
        <p>Effective as of March 28, 2022 </p>
      </section>
    </Container>
  )
}

export default PrivacyPolicy
